import { ServiceFactory } from '@/services/ServiceFactory'
import ZbBusinessTypeModal from './business-type-modal'
const _businessType = ServiceFactory.get('BusinessTypesService')
const _citiesService = ServiceFactory.get('CitiesService')
export default {
  components: {
    ZbBusinessTypeModal
  },
  data () {
    return {
      dialog: false,
      dialog2: false,
      loading: false,
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Imágen', value: 'image' },
        { text: 'Imágen v2', value: 'imageV2' },
        { text: 'Ciudad', value: 'city' },
        { text: 'Descripción', value: 'description' },
        { text: 'Abreviación', value: 'abbreviation' },
        // { text: 'Negocio', value: 'business' },
        // { text: 'Fecha inicio', value: 'dateFrom' },
        // { text: 'Fecha final', value: 'dateTo' },
        { text: 'Opciones', value: 'options', sortable: false }
      ],
      businessTypes: [],
      postData: {
        cityId: '',
        name: '',
        description: '',
        abbreviation: ''
        // provider: '',
        // description: '',
        // promoText: '',
        // dateFrom: '',
        // dateTo: ''
      },
      cities: [],
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // dates: [],
      menu: false,
      currentItem: null
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      this.currentItem = null
      try {
        const bTypesResp = await _businessType.index()
        const citiesResp = await _citiesService.index('Bolivia')
        this.businessTypes = bTypesResp.businessTypes
        this.cities = citiesResp
        // const promoCodeResponse = await _promoCodeService.index()
        // this.codes = promoCodeResponse.promoCode
        console.log(this.businessTypes)
      } catch (error) {
        console.log(error)
      }
    },
    // async submit () {
    //   this.loading = true
    //   try {
    //     this.postData.dateFrom = this.dates[0]
    //     this.postData.dateTo = this.dates[1]
    //     console.log(this.postData)
    //     await _promoCodeService.create(this.postData)
    //     this.dialog = false
    //     this.loading = false
    //     this.initialize()
    //     this.postData = {
    //       zbBarId: '',
    //       code: '',
    //       provider: '',
    //       description: '',
    //       promoText: '',
    //       dateFrom: '',
    //       dateTo: ''
    //     }
    //     this.dates = []
    //     this.$notify({
    //       type: 'success',
    //       text: 'Código promocional guardado correctamente'
    //     })
    //   } catch (error) {
    //     console.log(error)
    //     this.loading = false
    //     this.$notify({
    //       type: 'error',
    //       text: 'Ocurrió un error al guardar el código promocional'
    //     })
    //   }
    // },
    // closeModal2 () {
    //   this.dialog2 = false
    //   this.initialize()
    // },
    editItem (item) {
      console.log(item)
      this.currentItem = item
      // this.dialog2 = true
    },
    deleteItem (item) {
      this.$swal({
        title: '¿Está seguro de eliminar el tipo de negocio "' + item.name + '"?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await _businessType.destroy(item.id)
            this.$swal(
              'Tipo de negocio eliminado',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: 'Tipo de negocio no se pudo eliminar.'
            })
          }
        }
      })
    }
  }
}
